import React from 'react';

export default function () {

    return (
        <div className="content">
            Coming Soon
        </div>
    )

}